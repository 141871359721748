.root {
  font-family: Roboto, sans-serif;
}

* {
  box-sizing: border-box;
}

p {
  color: black !important;
}
@media (max-width: 600px) {
  h1 {
    font-size: 1.3rem;
  }
}

h2 {
  margin: 0px;
  color: white !important;
  font-size: 2rem;
}
@media (max-width: 600px) {
  h2 {
    font-size: 1.2rem;
  }
}

h3 {
  color: white !important;
  font-size: 20px;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  h3 {
    font-size: 14px;
  }
}
