/* navbar styles for verkaufen type (steppers) pages */
#navbar .stepMenu {
  background: #212121;
}
#navbar .stepMenu li.items a {
  color: #ffffff;
}

/* logotype styles for verkaufen type (steppers) pages and landing page */
#navbar .stepMenu .logoimgdiv {
  background: #212121;
}

#navbar .stepMenu .LanguageBar {
  background: #ffffff;
}

#navbar .stepMenu .LanguageBar span.grimme {
  color: #212121;
}
@media screen and (max-width: 768px) {
  #navbar .stepMenu .LanguageBar span.grimme {
    display: none;
  }
}

header {
  display: inline-block;
  width: 100%;
  min-width: 300px;
  font-family: "Roboto", sans-serif;
  background: #212121;
  height: 100%;
  z-index: 11;
}

.LanguageBar {
  background: #212121;
  text-align: right;
  padding: 10px 10px 2.5px 0px;
}
@media screen and (max-width: 768px) {
  .LanguageBar {
    padding: 4px 4px 2.5px 0px;
  }
}

.LanguageBarWrapperSmall {
  display: none;
}

@media screen and (max-width: 600px) {
  .LanguageBar {
    display: none;
  }
  .LanguageBarWrapperSmall {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }
  .LanguageBarWrapperSmall .LanguageBar {
    display: flex;
    padding: 0;
    background: transparent;
    top: 2.5px;
    position: relative;
    background: transparent !important;
  }
  .LanguageBarWrapperSmall .LanguageBar div {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 100%;
    margin-left: 10px;
  }
  .LanguageBarWrapperSmall .LanguageBar div img {
    padding: 0;
    width: 25px;
    height: 17px;
  }
}

.LanguageBar img {
  width: 25px;
  height: 17px;
  margin-left: 5px;
  cursor: pointer;
}

.red {
  border-bottom: 12px solid red !important;
}

#navbar {
  display: block;
  margin: 0 auto;
  background: #ffffff;
  width: 100%;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  z-index: 9;
  position: relative;
}

ul.menudesktop {
  list-style-type: none;
  padding-left: 0px;
  color: white;
  margin: 0;
}

#navbar .menu {
  display: inline-block;
  text-align: left;
  width: 100%;
  /* max-width:1150px; */
}

ul.menudesktop li {
  display: inline-block;
  height: 73px;
}

ul.menudesktop li.items a {
  height: 21px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}
ul.menudesktop li.tel a {
  font-weight: 900;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
}

ul.menudesktop li.items a:last-child {
  font-size: 16px;
  font-weight: 900;
  font-family: "Roboto", sans-serif !important;
  position: absolute;
  right: 2px;
}
ul.menudesktop li.items a:last-child:before {
  display: none;
}

ul.menudesktop a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding: 26px 5px 26px 5px;
  margin: 0px 17px;
  -webkit-transition: color 0.5s ease-in-out;
  -moz-transition: color 0.5s ease-in-out;
  -ms-transition: color 0.5s ease-in-out;
  -o-transition: color 0.5s ease-in-out;
}
ul.menudesktop li.items a:not(:last-child):hover {
  color: #c31924;
  -webkit-transition: color 0.5s ease-in-out;
  -moz-transition: color 0.5s ease-in-out;
  -ms-transition: color 0.5s ease-in-out;
  -o-transition: color 0.5s ease-in-out;
}
ul.menudesktop li.items a:not(:last-child):hover:after {
  transform: scaleX(1);
}
ul.menudesktop li.items a:not(:last-child).active-link:after {
  transform: scaleX(1);
}

ul.menudesktop li.items a:after {
  display: block;
  content: "";
  border-bottom: solid 4px #c31924;
  top: 22.5px;
  position: relative;
  width: 120%;
  right: 10%;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

a.hoverbot:after {
  display: none !important;
}
/*
picked class for navbar */
a.hoverbot:before {
  content: "";
  width: 120% !important;
  position: absolute;
  bottom: 0;
  left: -10%;
  right: 0;
  background: #c31924;
  display: inline-block;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
a.hoverbot:after {
  display: block;
  content: "";
  border-bottom: solid 3.8px #c31924;
  top: 21px;
  position: relative;
  width: 120%;
  right: 10%;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

#navbar ul a {
  color: #232323;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

#navbar span {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  padding: 0px 3px;
}
@media screen and (max-width: 768px) {
  #navbar span {
    padding: 0px;
  }
}

.logotype {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100%;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.logotype img {
  height: 44px;
  width: 206px;
  position: relative;
  left: 4px;
  top: 28px;
  transform: skew(14deg);
}
@media screen and (max-width: 768px) {
  .logotype img {
    height: 44px;
    width: 170px;
    top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .logotype img {
    width: 123px;
    height: 26px;
    top: 8px;
    left: 5px;
  }
}

.logoimgdiv {
  background: #ffffff;
  padding: 10px;
  margin-left: -16px;
  vertical-align: top;
  position: relative;
  z-index: 10;
  width: 291px;
  height: 130px;
  transform: skew(-14deg);
  text-align: center;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .logoimgdiv {
    width: 240px;
    height: 105px;
  }
}

@media screen and (max-width: 600px) {
  .logoimgdiv {
    width: 190px;
    height: 65px;
  }
}

.phone {
  padding: 0;
  margin: 0px;
  position: relative;
  right: 20px;
}

.phoneicon {
  position: relative;
  top: 7px;
  left: 25px;
  width: 25px;
  height: 25px;
}

.active {
  border-bottom: 3px solid #c5161d;
}
.active a {
  color: white !important;
}
.pointer {
  cursor: pointer;
}
.mobilemenu {
  display: none;
  z-index: 99;
  position: relative;
  background: #ffffff;
  padding: 10px 0px 10px 0px;
  text-align: center;
}

.mobilemenu img {
  width: 24px;
  height: 21px;
  cursor: pointer;
}
.mobilelist {
  list-style-type: none !important;
  margin-left: 0 !important;
  padding-left: 0px;
  padding-right: 0px;
}
.mobleMenuSpacer {
  height: 120px;
}
@media screen and (max-width: 768px) {
  .mobleMenuSpacer {
    height: 80px;
  }
}

@media screen and (max-width: 600px) {
  .mobleMenuSpacer {
    height: 50px;
  }
}

.logotypeMobile {
  width: 150px;
  height: 40px;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 20px 10px 10px 10px;
}
.mobilelist a {
  background: #ffffff;
  font-weight: 900;
  font-family: "Roboto", sans-serif !important;
  text-align: left;
  padding: 20px 45px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: #232323;
  display: block;
  position: relative;
  overflow: hidden;
  letter-spacing: 1px;
  transition: 0.5s ease-in-out;
}
.mobilelist a:hover {
  background: #c31924 !important;
  color: #ffffff !important;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 768px) {
  .mobilelist a {
    padding: 20px 25px;
  }
}
/* Landing page Menu */

li.landingmenu {
  padding: 1.2rem;
  width: 90% !important;
}

div.landingmenu {
  padding: 1rem;
  width: 90% !important;
  display: block;
  margin: 0 auto;
  text-align: Center;
}

span.landingmenu {
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: #ffffff !important;
}

.landingmenu img {
  width: 65px;
}

.cookiesbar {
  position: relative;
  width: 100%;
  max-width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 999999999;
  border-top: 1px solid white;
  background: #212121;
}

.cookiescontainer {
  max-width: 930px;
  min-width: 300px;
  display: none !important;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}

.cookiesbar p {
  font-size: 12px;
  line-height: 16px;
  color: #9f9f9f;
  font-weight: 100;
  letter-spacing: 0.5px;
}

.cookiesbar .buttondiv button {
  margin: 1rem 2rem;
  margin-left: 20px;
  text-transform: uppercase;
  padding: 7px 15px;
  border: 1px solid white;
  background: #23221d;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.cookiesbar a,
.cookiesbar p span {
  color: white;
}

.cookiesbar .textdiv {
  max-width: 80%;
  display: inline-block;
}

.cookiesbar .buttondiv {
  max-width: 10%;
  display: inline-block;
  width: 100%;
  text-align: center;
}

/* Flag */
.flag {
  padding: 0;
  margin: 0px;
  position: absolute;
  right: 10px;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
}
.flag img {
  width: 25px;
  height: 18px;
  cursor: pointer;
  padding-right: 5px;
  background: #212121;
  position: relative;
}

/* Modal with flags */
.modalwithflags > div:nth-child(2) {
  background: #0000009c !important;
  text-align: center;
}

.modalflag {
  height: 50px;
  width: 75px;
  margin: 1rem;
}

.modalflagcont {
  display: inline-block;
  cursor: pointer;
}

.modalflagcontmob {
  display: inline-block;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

.modalflagcontmob p {
  color: white !important;
  font-weight: 300;
  display: inline-block;
}

.modalflagcontmob img {
  max-width: 30px;
  max-height: 30px;
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  position: relative;
  border-radius: 50%;
  top: 10px;
  border: 1px solid white;
  float: left;
}

.modalflagcont:hover {
  background: #ffffff0a !important;
}

.modalflagcont p,
.dialogtitle h2 {
  text-align: center;
  color: white !important;
}

.modalflagcont img {
}

span.grimme {
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-weight: 300 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #777777 !important;
}
.LanguageBar div:first-child {
  display: inline-block;
  text-align: left;
  float: left;
  margin-left: 341px;
}

@media screen and (max-width: 768px) {
  .LanguageBar div:first-child {
    display: none;
  }
}

/* SAFARI IOS IPAD double a href tag fix */

navbar ul li a + a {
  display: none;
}

#navbar div.menu ul.menudesktop .items {
  margin-left: 318px;
  width: calc(100% - 318px);
}
.emailLink img {
  max-width: 20px;
  position: relative;
  vertical-align: middle;
  bottom: 1px;
  right: 10px;
  border: 0px;
}

/* Mobile phone number */
.mobilePhoneNr {
  color: #c31924 !important;
  border: 0px solid transparent !important;
}
/* end */

/* Media Queries */
@media screen and (max-width: 1440px) {
  ul.menudesktop li.items a:last-child {
    position: relative;
    top: 1px;
    float: right;
  }
}

@media screen and (max-width: 1020px) {
  .mobilemenu {
    display: block;
    z-index: 0;
    text-align: right;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  }
  #navbar ul a,
  .menu {
    display: none !important;
  }
}

@media screen and (max-width: 1020px) {
  li.tel {
    float: left !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 960px) {
  span.phone {
    display: none;
  }
}
@media screen and (min-width: 960px) {
  div.landingmobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .cookiesbar .textdiv {
    max-width: 100% !important;
    display: block !important;
  }
  .cookiesbar .buttondiv {
    max-width: 100% !important;
    display: block !important;
    text-align: center !important;
  }
  .cookiesbar .buttondiv button {
    position: relative !important;
    margin: 2rem 2rem 1rem 1rem !important;
  }
  #navbar {
    border: 0px solid black !important;
    box-shadow: none;
  }
}
@media screen and (max-width: 600px) {
  .LanguageBar div:first-child {
    margin-bottom: 10px;
  }
  .modalwithflags div {
    margin: 0 !important;
  }
  .flag {
    padding: 0px;
    margin: 0px;
    width: 100%;
    position: relative;
    display: block;
    margin-top: 1rem;
    right: 0px;
  }
  .flag img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    border: 1px solid white;
    padding: 0px !important;
  }
}

/* New mobile Menu */
.burgerMenuIconWrapper,
.burgerMenuIconWrapperBlack {
  display: block;
  height: 45px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .burgerMenuIconWrapper,
  .burgerMenuIconWrapperBlack {
    height: 35px;
  }
}

#navIcon2 {
  display: none;
  width: 23px;
  height: 16px;
  position: absolute;
  margin: 0;
  top: 15px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  right: 15px;
  z-index: 999;
}

@media screen and (max-width: 1020px) {
  #navIcon2 {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  #navIcon2 {
    top: 10px;
  }
}

#navIcon2.open span {
  background: #c31924;
  padding: 0 !important;
}

.burgerMenuIconWrapperBlack #navIcon2.open span {
  background: #ffffff !important;
}

#navIcon2 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: #232323;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  padding: 0 !important;
}

.burgerMenuIconWrapperBlack #navIcon2 span {
  background: #ffffff !important;
}

#navIcon2 span:nth-child(even) {
  left: 50%;
}

#navIcon2 span:nth-child(odd) {
  left: 0;
}

#navIcon2 span:nth-child(1),
#navIcon2 span:nth-child(2) {
  top: 0;
}

#navIcon2 span:nth-child(3),
#navIcon2 span:nth-child(4) {
  top: 7px;
}

#navIcon2 span:nth-child(5),
#navIcon2 span:nth-child(6) {
  top: 14px;
}

#navIcon2.open span:nth-child(1),
#navIcon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#navIcon2.open span:nth-child(2),
#navIcon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#navIcon2.open span:nth-child(1) {
  left: 2px;
  top: 3px;
}

#navIcon2.open span:nth-child(2) {
  left: calc(50% - 2px);
  top: 3px;
}

#navIcon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#navIcon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#navIcon2.open span:nth-child(5) {
  left: 2px;
  top: 11px;
}

#navIcon2.open span:nth-child(6) {
  left: calc(50% - 2px);
  top: 11px;
}

/* Additional Classes for mobile menu bar */
.movedAway {
  position: absolute;
  left: -250px;
}
