/*
  Navbar.css

  This file is an attempt (or a first step) to improve the 
  incorrent way of writing styles for the Header component
  (i.e. writing all the styles in a single .css file).
*/

.emailLink {
  display: inline-flex !important;
  font-size: 22px !important;
}

.emailLink > .material-icons {
  margin-right: 5px;
  color: #232323 !important;
  font-size: 24px !important;
}

ul.menudesktop li.items a:last-child {
  text-transform: none !important;
}
